import AboutEdit from './admin/AboutEdit';
import AdminTable from './admin/AdminTable';
import BannersEdit from './admin/BannersEdit';
import BlogsEdit from './admin/BlogsEdit';
import ContactEdit from './admin/ContactEdit';
import CourseEdit from './admin/CourseEdit';
import DetailsEdit from './admin/DetailsEdit';
import FaqEdit from './admin/FaqEdit';
import FavIcon from './admin/FavIcon';
// import FooterContentEdit from './admin/FooterContentEdit';
import FooterEdit from './admin/FooterEdit';
import HomeEdit from './admin/HomeEdit';
import LogoEdit from './admin/LogoEdit';
import Main from './admin/Main';
import NavbarEdit from './admin/NavbarEdit';
import PrivacyEdit from './admin/PrivacyEdit';
import ProfileEdit from './admin/ProfileEdit';
import ServiceEdit from './admin/ServiceEdit';
import TermsEdit from './admin/TermsEdit';
import './App.css';

import { BrowserRouter as Router, Route, Routes, useParams } from "react-router-dom";
import Login from './login/Login';
import Forgot from './login/Forgot';
import Reset from './login/Reset';
import BlogContentEdit from './admin/BlogContentEdit';
import UsersEdit from './admin/UsersEdit';
import UserDetailsEdit from './admin/UserDetailsEdit';
import AdminProfile from './admin/AdminProfile';
import NewRegister from './register/NewRegister';
import DriveDetails from './admin/DriveDetails';
import UserRideBookingDetails from './admin/UserRideBookingDetails';
import DriverRideDetails from './admin/DriverRideDetails';
import UserSafetySecurityDetails from './admin/UserSafetySecurityDetails';
import DriverSafetySecurityDetails from './admin/DriverSafetySecurityDetails';
import UserHelpSupportDetails from './admin/UserHelpSupportDetails';
import DriverHelpSupportDetails from './admin/DriverHelpSupportDetails';
import UserSafetySecurityAdd from './admin/UserSafetySecurityAdd';
import UserSafetySecurityEdit from './admin/UserSafetySecurityEdit';
import UserHelpSupportEdit from './admin/UserHelpSupportEdit';
import UserHelpSupportAdd from './admin/UserHelpSupportAdd';
import DriverSafetySecurityEdit from './admin/DriverSafetySecurityEdit';
import DriverSafetySecurityAdd from './admin/DriverSafetySecurityAdd';
import DriverHelpSupportEdit from './admin/DriverHelpSupportEdit';
import DriverHelpSupportAdd from './admin/DriverHelpSupportAdd';
import DriverDetailsViewEdit from './admin/DriverDetailsViewEdit';
import Privacypolicy from './components/Privacypolicy';
import SupportTeam from './components/SupportTeam';
import CouponsEdit from './admin/CouponEdit';
import ETOCoinAndDiscount from './admin/ETOCoinAndDiscount';
import ETOCoinAndDiscountEdit from './admin/ETOCoinAndDiscountEdit';
import EtoCoinAndDiscountAdd from './admin/EtoCoinAndDiscountAdd';
import LiveMap from './admin/LiveMap';
import DriverPayout from './admin/DriverPayout';
import RefundEdit from './admin/RefundEdit';
import ShippingEdit from './admin/ShippingEdit';
import TermsPolicy from './components/Terms';
import ShippingPolicy from './components/ShippingPolicy';
import RefundPolicy from './components/RefundPolicy';
import Aboutus from './components/Aboutus';
import ContactusEdit from './admin/Contactus';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route exact path="/adminDashboard" element={<Main />} />
        <Route exact path="/" element={<Login />} />
        <Route exact path="/forgot" element={<Forgot />} />
        <Route exact path="/reset" element={<Reset />} />
        <Route exact path="/driverDetails" element={<DriveDetails />} />
        <Route exact path="/driver-details-view/:driverId" element={<DriverDetailsViewEdit />} />

         <Route exact path="/table" element={<AdminTable />} /> 
         <Route exact path="/logo" element={<LogoEdit />} /> 
         <Route exact path="/favicon" element={<FavIcon />} /> 
         <Route exact path="/banners" element={<BannersEdit />} /> 

         <Route exact path="/navedit" element={<NavbarEdit />} /> 
         <Route exact path="/footeredit" element={<FooterEdit />} /> 
         <Route exact path="/driver-payout/:driverId" element={<DriverPayout />} />
         <Route exact path="/homeedit" element={<HomeEdit />} /> 
         <Route exact path="/aboutedit" element={<AboutEdit />} /> 
         <Route exact path="/contactusedit" element={<ContactusEdit />} /> 
         <Route exact path="/serviceedit" element={<ServiceEdit />} /> 
         <Route exact path="/contactedit" element={<ContactEdit />} /> 
         <Route exact path="/privacyedit" element={<PrivacyEdit />} /> 
         <Route exact path="/refundedit" element={<RefundEdit />} /> 
         <Route exact path="/shippingedit" element={<ShippingEdit />} /> 
         <Route exact path="/termsedit" element={<TermsEdit />} /> 
         <Route exact path="/detailsedit" element={<DetailsEdit />} /> 
         <Route exact path="/couponedit" element={<CouponsEdit />} /> 

         <Route exact path="/terms-condition" element={<TermsPolicy />} /> 
         <Route exact path="/shippingpolicy" element={<ShippingPolicy />} /> 
         <Route exact path="/refundpolicy" element={<RefundPolicy />} /> 
         <Route exact path="/privacypolicy" element={<Privacypolicy />} /> 
         <Route exact path="/support" element={<SupportTeam />} /> 
         <Route exact path="/aboutus" element={<Aboutus />} /> 


         <Route exact path="/usersedit" element={<UsersEdit />} /> 
         <Route exact path="/user-details/:id" element={<UserDetailsEdit />} /> 
         <Route exact path="/blogsedit" element={<BlogsEdit />} /> 
         <Route exact path="/blog-content" element={<BlogContentEdit />} /> 
         <Route exact path="/courseedit" element={<CourseEdit />} /> 
         {/* <Route exact path="/footerCedit" element={<FooterContentEdit />} />  */}
         <Route exact path="/faqedit" element={<FaqEdit />} /> 
         <Route exact path="/profileedit" element={<ProfileEdit />} /> 
         <Route exact path="/profile/:id" element={<AdminProfile />} /> 

         <Route exact path="/user-ride-booking" element={<UserRideBookingDetails />} />
         <Route exact path="/driver-ride-accept" element={<DriverRideDetails />} />
         <Route exact path="/live-map/:id" element={<LiveMap />} />

         <Route exact path="/user-safety-security" element={<UserSafetySecurityDetails />} />
         <Route exact path="/add-user-safety-security" element={<UserSafetySecurityAdd />} />
         <Route exact path="/edit-user-safety-security/:safetySecurityId" element={<UserSafetySecurityEdit />} />

         <Route exact path="/driver-safety-security" element={<DriverSafetySecurityDetails />} />
         <Route exact path="/add-driver-safety-security" element={<DriverSafetySecurityAdd />} />
         <Route exact path="/edit-driver-safety-security/:driverSafetySecurityId" element={<DriverSafetySecurityEdit />} />
         
         <Route exact path="/user-help-support" element={<UserHelpSupportDetails />} />
         <Route exact path="/add-user-help-support" element={<UserHelpSupportAdd />} />
         <Route exact path="/edit-user-help-support/:helpSupportId" element={<UserHelpSupportEdit />} />

         <Route exact path="/driver-help-support" element={<DriverHelpSupportDetails />} />
         <Route exact path="/add-driver-help-support" element={<DriverHelpSupportAdd />} />
         <Route exact path="/edit-driver-help-support/:driverHelpSupportId" element={<DriverHelpSupportEdit />} />

         <Route exact path="/rto-coin-discount" element={<ETOCoinAndDiscount />} />
         <Route exact path="/rto-coin-discount-edit/:etoId" element={<ETOCoinAndDiscountEdit />} />
         <Route exact path="/rto-coin-discount-add" element={<EtoCoinAndDiscountAdd />} />

         <Route exact path="/register" element={<NewRegister />} /> 
        </Routes>
      </Router>
    </div>
  );
}

export default App;
